<template>
  <div v-if="isLoggedIn" id="app">
    <app-layout>
      <router-view />
    </app-layout>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
const AppLayout = () => import('./components/AppLayout')
import { interceptors } from './lib/api'

export default {
  components: {
    AppLayout,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },
  created() {
    interceptors((error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('LOGOUT')
        .then(() => this.$router.push('/'))
      }
    })
  },
}
</script>