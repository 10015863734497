import { get } from '@/lib/api'

const state = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  image: '',
  role: '',
}

const getters = {
  isLoggedIn: state => !!state.id,
  isAdmin: state => state.role == 1,
  isUser: state => state.role == 3,
  fullName: state => `${state.firstname} ${state.lastname}`,
  userImage: state => state.image,
}

const actions = {
  async SET_USER ({ commit, dispatch }) {
    try {
      const user = await get({ url: 'auth/verify' })
      if (user) {
        dispatch('FETCH_BASE')
        commit('USER_DATA', user.data)
        return Promise.resolve()
      } else {
        dispatch('LOGOUT')
        return Promise.reject(user)
      }
    } catch (error) {
      dispatch('LOGOUT')
      return Promise.reject(error)
    }
  },
}

const mutations = {
  USER_DATA (state, payload) {
    state.id = payload.id
    state.firstname = payload.firstname
    state.lastname = payload.lastname
    state.email = payload.email
    state.image = payload.image
    state.role = payload.role_id
  },
  LOGOUT_USER (state) {
    state.id = ''
    state.firstname = ''
    state.lastname = ''
    state.email = ''
    state.image = ''
    state.role = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}