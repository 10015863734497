const state = {
  pending: null,
  info: {},
  contact: {},
  paymentInfo: {},
}

const getters = {
}

const actions = {
  SET_ORDER_ACTION({ commit }, order) {
    commit('SET_ORDER', order)
  },
}

const mutations = {
  SET_ORDER (state, payload) {
    state.info = payload
    state.pending = payload.pending
    state.contact = payload.contact
    state.paymentInfo = payload.payment_info
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}