import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './lib/filters'
import './registerServiceWorker'

// Libs
import 'bootstrap'
import Dayjs from 'vue-dayjs'
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import Toast from 'vue-toastification'
import VueLazyLoad from 'vue-lazyload'

import VueClipboard from 'vue-clipboard2'
import Pagination from 'vuejs-uib-pagination'
import VueCurrencyInput from 'vue-currency-input'
import SimpleVueValidation from 'simple-vue-validator'

// Styles
import 'vue-select/dist/vue-select.css'
import 'vue-toastification/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/main.css'

// Filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// Set user
if (store.state.auth.token) {
  store.dispatch('SHOW_LOADING')
  store.dispatch('SET_USER')
}

Vue.use(Dayjs)
Vue.component('VSelect', vSelect)
Vue.use(VTooltip)
Vue.use(Pagination)
Vue.use(VueLazyLoad)
Vue.use(VueClipboard)
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  hideProgressBar: true,
  toastClassName: 'ms-notification',
})
Vue.use(VueCurrencyInput)
Vue.use(SimpleVueValidation)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')