import { get } from '@/lib/api'

const state = {
  all: [],
  groupStatus: [],
  groupCount: {},
}

const getters = {
}

const actions = {
  async FETCH_ORDERS ({ commit }) {
    try {
      const orders = await get({ url: 'work_orders' })
      if (orders) {
        commit('SET_ORDERS', orders.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FILTER_ORDER_GROUPS ({ commit, dispatch }, group) {
    try {
      const orders = await get({
        url: 'work_orders',
        params: { group: group },
      })
      if (orders) {
        commit('SET_ORDERS', orders.data)
        await dispatch('FILTER_STATUSES', group)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FILTER_ORDERS({ commit }, filter) {
    try {
      const orders = await get({
        url: 'work_orders/filter',
        params: filter,
      })
      if (orders) {
        commit('SET_ORDERS', orders.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FILTER_STATUSES({ commit }, group) {
    try {
      const statuses = await get({
        url: 'statuses/filter',
        params: { group: group },
      })
      if (statuses) {
        commit('SET_GROUP_STATUS', statuses.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async FETCH_GROUP_COUNT({ commit }) {
    try {
      const count = await get({ url: 'work_orders/group_count' })
      if (count) {
        commit('SET_GROUP_COUNT', count.data)
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(error.data)
    }
  },
}

const mutations = {
  SET_ORDERS(state, payload) {
    state.all = payload
  },
  SET_GROUP_STATUS(state, payload) {
    state.groupStatus = payload
  },
  SET_GROUP_COUNT(state, payload) {
    state.groupCount = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}