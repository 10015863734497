import { createHelpers } from 'vuex-map-fields'
import { post, postFile } from '@/lib/api'

const { getNewOrderField, updateNewOrderField } = createHelpers({
  getterType: 'getNewOrderField',
  mutationType: 'updateNewOrderField',
})

const state = {
  order: {
    type_repair: '',
    location: '',
    stored: '',
    bill_to_id: '',
    contact_id: '',
    account_id: '',
  },
  detail: {
    product_category_id: '',
    brand_id: '',
    model: '',
    item_description: '',
    serial: '',
    serial_verified: false,
    date_purchase: null,
    date_purchase_verified: false,
    reference_number: '',
    approval_number: '',
    fault_description: '',
    repair_description: '',
  },
  delivery: {
    delivery_type: '',
    details: '',
    contact_name: '',
    address: '',
  },
  serialImage: null,
  datePurchaseImage: null,
  images: [],
}

const getters = {
  getNewOrderField,
}

const actions = {
  async SAVE_WORK_ORDER ({ dispatch, state, commit }) {
    try {
      const order = await post({
        url: 'service_requests/create',
        data: {
          work_order: state.order,
          delivery: state.delivery,
          repair_detail: state.detail,
        },
      })
      if (order.data.success) {
        await dispatch('UPLOAD_ORDER_IMAGES', order.data.id)
        dispatch('FETCH_ORDERS')
        commit('CLEAN_NEW_ORDER_FORM')
        return Promise.resolve(order.data.id)
      }
    } catch (errors) {
      return Promise.reject(errors.data)
    }
  },

  async UPLOAD_ORDER_IMAGES({ state }, id) {
    let formData = new FormData()

    if (state.serialImage)
      formData.append('serial_image', state.serialImage)
    if (state.datePurchaseImage)
      formData.append('proof_purchase', state.datePurchaseImage)
    if (state.images.length > 0) {
      for (const file of state.images) {
        formData.append('images[]', file)
      }
    }

    if (state.serialImage || state.datePurchaseImage || state.images.length > 0) {
      await postFile({
        url: `work_orders/${id}/repair_details/upload_image`,
        data: formData,
      })
    }
  },
}

const mutations = {
  CLEAN_NEW_ORDER_FORM (state) {
    state.order = {
      type_repair: '',
      location: '',
      stored: '',
      bill_to_id: '',
      contact_id: '',
      account_id: '',
    }
    state.detail = {
      product_category_id: '',
      brand_id: '',
      model: '',
      item_description: '',
      serial: '',
      serial_verified: false,
      date_purchase: null,
      date_purchase_verified: false,
      reference_number: '',
      approval_number: '',
      fault_description: '',
      repair_description: '',
    }
    state.delivery = {
      delivery_type: '',
      details: '',
      contact_name: '',
      address: '',
    }
    state.serialImage = null
    state.datePurchaseImage = null
    state.images = []
  },
  updateNewOrderField,
}

export default {
  state,
  getters,
  actions,
  mutations,
}