import { postAuth } from '@/lib/api'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
}

const actions = {
  LOGIN ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      postAuth({ 
        url: 'auth/login', 
        data: user,
      })
      .then((res) => {
        const token = res.data.auth_token
        localStorage.setItem('user-token', token)
        commit('SET_TOKEN', token)
        dispatch('SET_USER')
        resolve()
      })
      .catch(err => {
        reject(err.data.error)
      })
    })
  },

  GOOGLE_LOGIN ({ commit, dispatch }, token) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', token)
      dispatch('SET_USER').then(() => {
        localStorage.setItem('user-token', token)
        resolve()
      })
      .catch(err => {
        reject(err.token)
      })
      
    })
  },

  REFRESH_TOKEN ({ commit }, token) {
    localStorage.setItem('user-token', token)
    commit('SET_TOKEN', token)
  },

  LOGOUT ({ commit }) {
    return new Promise((resolve) => {
      commit('LOGOUT_REQUEST')
      commit('LOGOUT_USER')
      localStorage.removeItem('user-token')
      resolve()
    })
  },
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },
  LOGOUT_REQUEST (state) {
    state.token = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}