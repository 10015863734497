import Vue from 'vue'
import Vuex from 'vuex'
import loading from '@/components/loading'

import base from './modules/base'
import auth from './modules/auth'
import user from './modules/user'
import order from './modules/order'
import orders from './modules/orders'
import newOrder from './modules/newOrder'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    base,
    auth,
    user,
    order,
    orders,
    newOrder,
  },
  actions: {
    SHOW_LOADING () {
      loading.show()
    },
    HIDE_LOADING () {
      loading.hide()
    },
  },
})
