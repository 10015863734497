/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import bar from './components/progress'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/work-orders')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.commit('SET_TOKEN', localStorage.getItem('user-token'))
    next()
    return
  }
  next('/login')
}

const isAdmin = (to, from, next) => {
  if (store.getters.isAdmin) {
    next()
    return
  }
  next('/work-orders')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/auth/Login.vue'),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import('./views/misc/NotFound.vue')
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('./views/contacts/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contacts/new',
      name: 'contacts-new',
      component: () => import('./views/contacts/Form.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contacts/:id',
      name: 'contacts-show',
      component: () => import('./views/contacts/Show.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contacts/:id/edit',
      name: 'contacts-edit',
      component: () => import('./views/contacts/Form.vue'),
      meta: { mode: 'edit' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/companies/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies/new',
      name: 'companies-new',
      component: () => import('./views/companies/Form.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies/:id',
      name: 'companies-show',
      component: () => import('./views/companies/Show.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/companies/:id/edit',
      name: 'companies-edit',
      component: () => import('./views/companies/Form.vue'),
      meta: { mode: 'edit' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/work-orders',
      component: () => import('./views/work-orders/Base.vue'),
      children: [
        {
          path: '',
          name: 'work-orders-index',
          component: () => import('./views/work-orders/Index.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: 'new',
          name: 'work-orders-new',
          component: () => import('./views/work-orders/New.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: ':id',
          component: () => import('./views/work-orders/Show.vue'),
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: '',
              name: 'history',
              component: () => import('./views/history/Index.vue')
            },
            {
              path: 'repair-details',
              name: 'repair-details',
              component: () => import('./views/repair-details/Index.vue')
            },
            {
              path: 'appointments',
              name: 'appointments',
              component: () => import('./views/appointments/Index.vue')
            },
            {
              path: 'parts-request',
              name: 'parts-request',
              component: () => import('./views/parts-request/Index.vue')
            },
            {
              path: 'fees-payments',
              name: 'fees-payments',
              component: () => import('./views/fees-payments/Index.vue')
            },
          ]
        },
      ]
    },
    {
      path: '/parts-inventory',
      name: 'parts-inventory',
      component: () => import('./views/parts-inventory/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reports/payments',
      name: 'reports-payments',
      component: () => import('./views/reports/Payments.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reports/on-account',
      name: 'reports-on-account',
      component: () => import('./views/reports/OnAccount.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reports/closed',
      name: 'reports-closed',
      component: () => import('./views/reports/Closed.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/scheduling',
      name: 'scheduling',
      component: () => import('./views/scheduling/Index.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('./views/admin/Index.vue'),
      beforeEnter: isAdmin
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      component: () => import('./views/admin/Users.vue'),
      beforeEnter: isAdmin
    },
    {
      path: '/admin/settings',
      name: 'admin-settings',
      component: () => import('./views/admin/Settings.vue'),
      beforeEnter: isAdmin
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name != 'login' && to.name != 'not-found') bar.start()
  next()
})

export default router