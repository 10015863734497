import axios from 'axios'
import store from '../store'

const api = axios.create({
  baseURL: process.env.VUE_APP_URL,
})

export function get({ url, params }) {
  let options = {
    method: 'GET',
    url: url,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
    },
  }
  if (params) {
    let newParams = { params: params }
    options = { ...options, ...newParams }
  }  
  return api(options)
}

export function post({ url, data }) {
  let options = {
    method: 'POST',
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
    },
  }
  return api(options)
}

export function postFile({ url, data }) {
  let options = {
    method: 'POST',
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  return api(options)
}

export function postAuth({ url, data }) {
  let options = {
    method: 'POST',
    url: url,
    data: data,
  }
  return api(options)
}

export function put({ url, data }) {
  let options = {
    method: 'PUT',
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
    },
  }
  return api(options)
}

export function byMethod({ url, method, data }) {
  let options = {
    method: method,
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
    },
  }
  return api(options)
}

export function del({ url, data }) {
  let options = {
    method: 'DELETE',
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${store.state.auth.token}`,
    },
  }
  return api(options)
}

export function interceptors(callback) {
  api.interceptors.response.use((response) => {
    if (response.headers.auth_token) {
      store.dispatch('REFRESH_TOKEN', response.headers.auth_token)
    }
    return response
  }, (error) => {
    callback(error)
    return Promise.reject(error.response)
  })
}